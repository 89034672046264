<template>
  <div>
    <a-card>
      <a-steps>
        <a-step
          title="添加主播"
          description="搜索添加主播进监控列表"
          status="process"
        />
        <a-step
          title="检查和选择"
          description="检查监控列表；选择监控规则；选择视频监控起始时间"
          status="process"
        />
        <a-step
          title="确认添加"
          description="确认无误后点击“确认点击”按钮"
          status="process"
        />
      </a-steps>
    </a-card>
    <a-row :gutter="[16, 16]" style="margin-top: 8px">
      <a-col :span="12">
        <a-card>
          <h3>搜索并添加进监控主播列表</h3>
          <author-manage @addAuthorList="addAuthorList" />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card>
          <h3>检查监控主播列表并选择规则</h3>
          <div>
            <p>1. 检查监控列表</p>
            <p class="regulation_tips">
              因系统性能限制，系统最多监控30位主播，系统已监控{{
                author_count.total_monit_size
              }}位主播，剩下{{ 30 - author_count.total_monit_size }}个名额
            </p>
            <!-- 列表 -->
            <a-table
              :rowKey="(record, index) => index"
              :columns="columns"
              :data-source="monit_data"
              :pagination="false"
              :scroll="{ y: 410 }"
            >
              <div slot="avatar" slot-scope="text">
                <a-avatar :size="42" :src="text" v-if="text" />
                <a-avatar :size="42" icon="user" v-else />
              </div>
              <div slot="accedit" slot-scope="text, record">
                <a-tag
                  :color="judgeColor(record.creator_platform_oauth_status)"
                  v-if="record.open_platform_oauth_status !== undefined"
                  >p1</a-tag
                >
                <a-tag
                  :color="judgeColor(record.open_platform_oauth_status)"
                  v-if="record.open_platform_oauth_status !== undefined"
                  >p2</a-tag
                >
                <!--                   v-if="record.creator_platform_oauth_status != undefined"
                    v-if="record.p1_oauth_status !== undefined" -->
                <a-tag
                  :color="judgeColor(record.p1_oauth_status)"
                  v-if="record.p1_oauth_status !== undefined"
                  >p1</a-tag
                >
                <a-tag
                  :color="judgeColor(record.p2_oauth_status)"
                  v-if="record.p1_oauth_status !== undefined"
                  >p2</a-tag
                >
              </div>
              <div slot="action" slot-scope="text, record">
                <a-button type="link" @click="deleteMonitAuthor(text)"
                  >删除</a-button
                >
              </div>
            </a-table>
          </div>
          <div style="margin: 16px 0">
            <div style="margin-bottom: 8px">选择监控规则：</div>
            <div>
              <a-select
                label-in-value
                v-model="rule_value"
                placeholder="输入搜索活动列表"
                style="width: 40%"
                :filter-option="false"
              >
                <a-select-option v-for="d in rule_list" :key="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <a-button
            type="primary"
            :loading="btnAddLoading"
            @click="saveAuthorMonit"
            >确认添加</a-button
          >
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AuthorManage from "./components/AuthorManage.vue";
import buryEvent from '@/utils/buryEvent.js'
const columns = [
  {
    title: "头像",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "昵称",
    dataIndex: "nickname",
  },
  {
    title: "平台",
    dataIndex: "platform",
  },
  {
    title: "平台号",
    dataIndex: "code",
  },
  {
    title: "授权状态",
    dataIndex: "address",
    scopedSlots: { customRender: "accedit" },
  },
  {
    title: "操作",
    dataIndex: "author_id",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    AuthorManage,
  },
  data() {
    return {
      columns,
      monit_data: [],
      author_count: {},
      rule_list: [],
      btnAddLoading: false,
      rule_value: [],
    };
  },
  mounted() {
    this.checkAuhtor();
    this.$api.core.PutAssistant.ruleGruopList().then((res) => {
      this.rule_list = res.data;
    });
  },
  methods: {
    checkAuhtor() {
      this.$api.core.PutAssistant.getMonitAuthor().then((res) => {
        if (res.code == 0) {
          this.author_count = res.data;
        }
      });
    },
    // 颜色定义
    judgeColor(value) {
      let color = "";
      switch (value) {
        case 1:
          color = "green";
          break;
        case 2:
          color = "red";
          break;
        case null:
          color = "red";
          break;
        default:
          color = "";
          break;
      }
      return color;
    },
    /**
     * 数据对象key 驼峰下划线相互转化
     * @param {Object} data - 原始对象 支持-数组、key-value对象、字符串
     * @param {String} type hump-转驼峰 toLine-转下划线
     */
    formatHumpLineTransfer(data, type = "toLine") {
      let hump = "";
      // 转换对象中的每一个键值为驼峰的递归
      let formatTransferKey = (data) => {
        if (data instanceof Array) {
          data.forEach((item) => formatTransferKey(item));
        } else if (data instanceof Object) {
          for (let key in data) {
            hump =
              type === "hump" ? this.formatToHump(key) : this.formatToLine(key);
            data[hump] = data[key];
            if (key !== hump) {
              delete data[key];
            }
            if (data[hump] instanceof Object) {
              formatTransferKey(data[hump]);
            }
          }
        } else if (typeof data === "string") {
          data =
            type === "hump" ? this.formatToHump(data) : this.formatToLine(data);
        }
      };
      formatTransferKey(data);
      return data;
    },
    /**
     * 字符串下划线转驼峰
     * @param {String} value 需要转换的值
     */
    formatToHump(value) {
      return value.replace(/\_(\w)/g, (_, letter) => letter.toUpperCase());
    },

    /**
     * 字符串驼峰转下划线
     * @param {String} value
     */
    formatToLine(value) {
      return value.replace(/([A-Z])/g, "_$1").toLowerCase();
    },

    addAuthorList(value) {
      let use_value = this.formatHumpLineTransfer(value);
      let arr1 = this.monit_data.concat(use_value);
      let hash = {};
      let arr2 = [];
      arr2 = arr1.reduce((preVal, curVal) => {
        hash[curVal.author_id]
          ? ""
          : (hash[curVal.author_id] = true && preVal.push(curVal));
        return preVal;
      }, []);
      this.monit_data = arr2;
    },
    // 删除
    deleteMonitAuthor(id) {
      const screen_arr = this.monit_data.filter((item) => {
        return !id.includes(item.author_id);
      });
      this.monit_data = screen_arr;
    },
    // 过滤不需要的对象
    filterObj(obj, arr) {
      let newObj = {};
      let newArr = [];
      obj.forEach((item) => {
        for (let key in item) {
          if (key == arr[0] || key == arr[1]) {
            newObj[key] = item[key];
          }
        }
        newArr.push(newObj);
        newObj = {};
      });
      return newArr;
    },
    // 确认添加主播
    saveAuthorMonit() {
      if (!this.monit_data.length) {
        this.$message.warning("请添加监控主播！");
        return;
      }
      if (!this.rule_value.key) {
        this.$message.warning("请选择监控规则！");
        return;
      }
      let arr1 = this.filterObj(this.monit_data, ["author_id", "platform"]);

      arr1.map((item) => {
        switch (item.platform) {
          case "抖音":
            item.platform = 1;
            break;
          case "快手":
            item.platform = 2;
            break;
          case "懂车帝":
            item.platform = 3;
            break;
          default:
            break;
        }
      });
      let data = {
        author: arr1,
        rule_group_id: this.rule_value.key,
      };
      this.btnAddLoading = true;
      this.$api.core.PutAssistant.saveAuthorMonit(data)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("成功添加监控主播！");
            this.monit_data = [];
            this.checkAuhtor();
            buryEvent("monitor", "add-success")
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          this.$message.error("添加监控主播失败！");
        })
        .finally(() => {
          this.btnAddLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.ant-steps-item-description {
  max-width: 200px !important;
}
.search_box {
  width: 500px;
  margin-left: 40px;
}
.regulation_tips {
  font-size: 12px;
  color: rgb(228, 120, 42);
  margin-left: 16px;
}
</style>