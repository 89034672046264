<template>
  <div>
    <div class="search_box">
      <a-row>
        <a-col span="6">
          <a-select
            style="width: 100%"
            size="large"
            v-model="platform"
            disabled
          >
            <a-select-option value="douyin"> 抖音 </a-select-option>
            <a-select-option value="kuaishou"> 快手 </a-select-option>
            <a-select-option value="dongchedi"> 懂车帝 </a-select-option>
          </a-select>
        </a-col>
        <a-col span="16">
          <a-input-search
            placeholder="请输入主播昵称"
            enter-button="搜索"
            size="large"
            v-model="author_name"
            @search="onSearch"
          />
        </a-col>
      </a-row>
      <a-row style="margin: 16px 0">
        <a-col span="6" style="font-weight: bold"> 或选择活动列表： </a-col>
        <a-col span="16">
          <a-select
            show-search
            label-in-value
            :value="active_value"
            placeholder="输入搜索活动列表"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="fetching ? undefined : null"
            @search="fetchActive"
            @change="handleChange"
            size="large"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in active_list" :key="d.id">
              {{ d.activity_name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>
    <div>
      <div style="margin-bottom: 16px">
        <span style="margin-left: 8px">
          <template>
            {{ `已选择 ${selectedRowKeys.length} 条数据` }}
          </template>
        </span>
        <a-button
          type="link"
          :disabled="!hasSelected"
          :loading="loading"
          @click="addAuthorTable"
        >
          批量添加
        </a-button>
      </div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="table_data"
        :pagination="false"
        :loading="tableLoading"
        :scroll="{ y: 340 }"
      >
        <div slot="status_title">
          授权状态
          <a-tooltip placement="top">
            <template slot="title">
              <span>任意级别授权为绿色即可</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip>
        </div>
        <div slot="avatar" slot-scope="text">
          <a-avatar :size="42" :src="text" v-if="text" />
          <a-avatar :size="42" icon="user" v-else />
        </div>

        <div slot="accedit" slot-scope="text, record">
          <a-tag
            v-if="!judge_type"
            :color="judgeColor(record.creatorPlatformOauthStatus)"
            >p1</a-tag
          >
          <a-tag
            v-if="!judge_type"
            :color="judgeColor(record.openPlatformOauthStatus)"
            >p2</a-tag
          >
          <a-tag v-if="judge_type" :color="judgeColor(record.p1_oauth_status)"
            >p1</a-tag
          >
          <a-tag v-if="judge_type" :color="judgeColor(record.p2_oauth_status)"
            >p2</a-tag
          >
        </div>
        <div slot="action" slot-scope="text, record">
          <a-button type="link" @click="addMonitAuthor(record)">添加</a-button>
        </div>
      </a-table>
      <!-- 分页配置 -->
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
      >
      </base-pagination>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import buryEvent from '@/utils/buryEvent.js'

const columns = [
  {
    title: "头像",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "昵称",
    dataIndex: "nickname",
  },
  {
    title: "平台",
    dataIndex: "platform",
  },
  {
    title: "平台号",
    dataIndex: "code",
  },
  {
    slots: { title: "status_title" },
    dataIndex: "openPlatformOauthStatus",
    scopedSlots: { customRender: "accedit" },
  },
  {
    title: "操作",
    dataIndex: "author_id",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    this.fetchActive = debounce(this.fetchActive, 800);
    return {
      table_data: [],
      columns,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      platform: "douyin",
      current_select: 1,
      author_name: "",
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      tableLoading: false,
      activeId: "",
      active_list: [],
      fetching: false,
      fetch_current: 1,
      btnLoading: false,
      active_value: [],
      judge_type: false,
      selectedRowArr: [],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    onSearch() {
      this.active_value = [];
      this.judge_type = false;
      this.getAuthorList(1, 10);
      buryEvent("monitor", "search-nickname")
    },
    // 获取主播列表
    getAuthorList(current, page_size) {
      let params = {
        type: this.platform,
        data: {
          nickname: this.author_name,
          current,
          page_size,
        },
      };
      this.tableLoading = true;
      this.$api.core.PutAssistant.getAuthorList(params)
        .then((res) => {
          if (res.code == 200) {
            let list = res.data.list;
            let platform_code = "";
            switch (this.platform) {
              case "douyin":
                platform_code = "抖音";
                break;
              case "kuaishou":
                platform_code = "快手";
                break;
              case "dongchedi":
                platform_code = "懂车帝";
                break;
              default:
                break;
            }
            list.forEach((element) => {
              element.platform = platform_code;
            });
            this.table_data = res.data.list
            const { current, page_size, total } = res.data.pagination;
            this.pagination = {
              current,
              page_size: page_size,
              total: total,
            };
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addMonitAuthor(value) {
      this.$emit("addAuthorList", [value]);
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      if (this.judge_type) {
        this.getDealerList(current, pageSize);
      } else {
        this.getAuthorList(current, pageSize);
      }
    },
    addAuthorTable() {
      this.loading = true;
      this.$emit("addAuthorList", this.selectedRowArr);
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys, record) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowArr = JSON.parse(JSON.stringify(record)); 
    },
    // 颜色定义
    judgeColor(value) {
      let color = "";
      switch (value) {
        case 1:
          color = "green";
          break;
        case 2:
          color = "red";
          break;
        case null:
          color = "red";
          break;
        default:
          color = "";
          break;
      }
      return color;
    },
    fetchActive(value) {
      let params = {
        activity_name: value,
        current: this.fetch_current,
        page_size: 50,
      };
      this.fetching = true;
      this.$api.core.PutAssistant.getActivityList(params)
        .then((res) => {
          this.active_list = res.data.list;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    // 选择活动后
    handleChange(value) {
      this.judge_type = true;
      this.author_name = "";
      this.active_value = value;
      this.getDealerList(1, 10);
      buryEvent("monitor", "search-select")
    },
    // 经销商列表
    getDealerList(current, page_size) {
      this.tableLoading = true;
      let params = {
        activity_id: this.active_value.key,
        current,
        page_size,
        platform: "抖音",
      };
      this.$api.core.PutAssistant.getDealerList(params)
        .then((res) => {
          this.table_data = res.data.list;
          const { current, page_size, total } = res.data.pagination;
          this.pagination = {
            current,
            page_size: page_size,
            total: total,
          };
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-steps-item-description {
  max-width: 200px !important;
}
</style>